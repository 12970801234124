import React, { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';

// layouts
import DefaultLayout from '../layouts/Default';
import VerticalLayout from '../layouts/Vertical';
import HorizontalLayout from '../layouts/Horizontal/';

// components
import PrivateRoute from './PrivateRoute';
import Root from './Root';

// constants
import { LayoutTypes } from '../constants';

// hooks
import { useRedux } from '../hooks';

// lazy load all the views
// auth
const Login = React.lazy(() => import('../pages/auth/Login'));
const Register = React.lazy(() => import('../pages/auth/Register'));
const ForgetPassword = React.lazy(() => import('../pages/auth/ForgetPassword'));
const ResetPassword = React.lazy(() => import('../pages/auth/ResetPassword'));
const Logout = React.lazy(() => import('../pages/auth/Logout'));
const Payment = React.lazy(() => import('../pages/auth/Payment'));
const VerifyEmail = React.lazy(() => import('../pages/auth/VerifyEmail'));
const PaymentCompleted = React.lazy(() => import('../pages/auth/PaymentCompletion'));

// dashboards
const DashBoard1 = React.lazy(() => import('../pages/dashboards/DashBoard1/'));

// Admin Panel
const AdminPanel = React.lazy(() => import('../pages/AdminPanel/AdminPanel'));
const PendingTransactionRecords = React.lazy(() => import('../pages/AdminPanel/PendingTransactionRecords'));
const PendingWithdrawalRecords = React.lazy(() => import('../pages/AdminPanel/PendingWithdrawalRecords'));
const ResendVerificationEmails = React.lazy(() => import('../pages/AdminPanel/ResendVerificationEmails'));
const UpdateUserEmail = React.lazy(() => import('../pages/AdminPanel/UpdateUserEmail'));
const DailySalesReport = React.lazy(() => import('../pages/AdminPanel/DailySalesReport'));
const WithdrawalHistory = React.lazy(() => import('../pages/AdminPanel/WithdrawalHistory'));
const PaymentHistory = React.lazy(() => import('../pages/AdminPanel/PaymentHistory'));
const SupportTickets = React.lazy(() => import('../pages/AdminPanel/SupportTickets'));

// Wallet
const MyWallet = React.lazy(() => import('../pages/wallet/MyWallet'));

// Earnings
const MyEarnings = React.lazy(() => import('../pages/earnings/MyEarnings'));

// Tickets
const MyTickets = React.lazy(() => import('../pages/tickets/MyTickets'));

// User Profile

const Profile = React.lazy(() => import('../pages/user/Profile'));


// Referral Tree

const ReferralTree = React.lazy(() => import('../pages/other/Timeline'));


// Courses

const BeginnerCourse = React.lazy(() => import('../pages/courses/Forex/Beginner'));
const IntermediateCourse = React.lazy(() => import('../pages/courses/Forex/Intermediate'));
const AdvancedCourse = React.lazy(() => import('../pages/courses/Forex/Advanced'));
const MasterCourse = React.lazy(() => import('../pages/courses/Forex/Master'));


const loading = () => <div className=""></div>;

type LoadComponentProps = {
	component: React.LazyExoticComponent<any>;
};

const LoadComponent = ({ component: Component }: LoadComponentProps) => (
	<Suspense fallback={loading()}>
		<Component />
	</Suspense>
);

const AllRoutes = () => {
	const { appSelector } = useRedux();

	const { layout } = appSelector((state) => ({
		layout: state.Layout,
	}));

	const getLayout = () => {
		let layoutCls: React.ComponentType = VerticalLayout;

		switch (layout.layoutType) {
			case LayoutTypes.LAYOUT_HORIZONTAL:
				layoutCls = HorizontalLayout;
				break;
			default:
				layoutCls = VerticalLayout;
				break;
		}
		return layoutCls;
	};
	let Layout = getLayout();

	return useRoutes([
		{ path: '/', element: <Root /> },
		{
			// public routes
			path: '/',
			element: <DefaultLayout />,
			children: [
				{
					path: 'auth',
					children: [
						{ path: 'login', element: <LoadComponent component={Login} /> },
						{ path: 'register', element: <LoadComponent component={Register} /> },
						{ path: 'forget-password', element: <LoadComponent component={ForgetPassword} /> },
						{ path: 'reset-password', element: <LoadComponent component={ResetPassword} /> },
						{ path: 'logout', element: <LoadComponent component={Logout} /> },
						{ path: 'verify-email', element: <LoadComponent component={VerifyEmail} /> },
						{ path: 'payment', element: <LoadComponent component={Payment} /> },
						{ path: 'payment-completed', element: <LoadComponent component={PaymentCompleted} /> },
					],
				},
				
			],
		},
		// admin protected routes
		{
			path: '/',
			element: <PrivateRoute roles={['admin']} component={Layout} />,
			children: [
				{
					path: 'admin-panel',
					element: <LoadComponent component={AdminPanel} />,
					children: [
						{
							path: 'pending-transaction-records',
							element: <LoadComponent component={PendingTransactionRecords} />,
						},
						{
							path: 'pending-withdrawal-records',
							element: <LoadComponent component={PendingWithdrawalRecords} />,
						},
						{
							path: 'resend-verification-emails',
							element: <LoadComponent component={ResendVerificationEmails} />,
						},
						{
							path: 'update-user-emails',
							element: <LoadComponent component={UpdateUserEmail} />,
						},
						{
							path: 'daily-sales-report',
							element: <LoadComponent component={DailySalesReport} />,
						},
						{
							path: 'withdrawal-history',
							element: <LoadComponent component={WithdrawalHistory} />,
						},
						{
							path: 'payment-history',
							element: <LoadComponent component={PaymentHistory} />,
						},
						{
							path: 'support-tickets',
							element: <LoadComponent component={SupportTickets} />,
						},

					],
				},
			],
		},
		{
			// auth protected routes
			path: '/',
			element: <PrivateRoute roles={['user', 'admin']} component={Layout} />,
			children: [
				{
					path: 'dashboard',
					element: <LoadComponent component={DashBoard1} />,
				},
				{
					path: 'my-wallet',
					element: <LoadComponent component={MyWallet} />,
				},
				{
					path: 'my-earnings',
					element: <LoadComponent component={MyEarnings} />,
				},
				{
					path: 'my-support-tickets',
					element: <LoadComponent component={MyTickets} />,
				},
				{
					path: 'profile',
					element: <LoadComponent component={Profile} />,
				},
				{
					path: 'pages',
					children: [
						{
							path: 'referrals',
							element: (
								<Suspense fallback={<div>Loading...</div>}>
									<ReferralTree />
								</Suspense>
							),
						},
						{
							path: 'courses',
							element: <PrivateRoute profileStatus={['Activated']} />,
							children: [
								{
									path: 'Forex/Beginner',
									element: <LoadComponent component={BeginnerCourse} />,
								},
								{
									path: 'Forex/Intermediate',
									element: <LoadComponent component={IntermediateCourse} />,
								},
								{
									path: 'Forex/Advanced',
									element: <LoadComponent component={AdvancedCourse} />,
								},
								{
									path: 'Forex/Master',
									element: <LoadComponent component={MasterCourse} />,
								},
							],
						},
					],
				},
				
			],
		},
	]);
};

export { AllRoutes };
