import { Navigate, Outlet, useLocation } from 'react-router-dom';

// helpers
import { APICore } from '../helpers/api/apiCore';

// hooks
import { useUser } from '../hooks';
import React from 'react';

type PrivateRouteProps = {
	component?: React.ComponentType | React.ElementType;
	roles?: string[];
	profileStatus?: string[];
};

/**
 * Private Route forces the authorization before the route can be accessed
 * @param {*} param0
 * @returns
 */
const PrivateRoute = ({ component: RouteComponent = Outlet, roles, profileStatus, ...rest }: PrivateRouteProps) => {
	let location = useLocation();
	const [loggedInUser] = useUser();

	const api = new APICore();

	/**
	 * not logged in so redirect to login page with the return url
	 */
	if (api.isUserAuthenticated() === false) {
		return <Navigate to={'/auth/login'} state={{ from: location }} replace />;
	}

	// check if route is restricted by role
	const isRestrictedByRole = roles && roles.length > 0 && !roles.includes(loggedInUser.role);
	// check if route is restricted by profile status
	const isRestrictedByProfileStatus =
		profileStatus && profileStatus.length > 0 && !profileStatus.includes(loggedInUser.profile_status);

	if (isRestrictedByRole || isRestrictedByProfileStatus) {
		// not authorised so redirect to home page
		return <Navigate to={{ pathname: '/' }} />;
	}

	return <RouteComponent />;
};

export default PrivateRoute;
